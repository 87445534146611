import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Identity from "../components/Identity"

class Index extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <div>
        <SEO
          title="Home"
          keywords={[`davidlai.org`, `David Lai`, `front-end developer`]}
        />
        <Identity name={siteTitle} />
      </div>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
