import React from "react"

import identityStyles from './identity.module.css';

const Identity = ({name}) => {
  return (
    <div className={identityStyles.identity}>
      <h1 className={identityStyles.name}>{name}</h1>
    </div>
  )
}

export default Identity
